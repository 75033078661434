document.querySelectorAll(".btn-toggle-password").forEach((toggleButton) => {
  toggleButton.addEventListener("click", () => {
    const passwordInput = toggleButton.closest(".input-group").querySelector(".password-toggle-input");
    if (passwordInput) {
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
        toggleButton.innerHTML = '<i class="fas fa-eye-slash"></i>';
      } else {
        passwordInput.type = "password";
        toggleButton.innerHTML = '<i class="fas fa-eye"></i>';
      }
    }
  });
});